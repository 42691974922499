exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-category-js": () => import("./../../../src/pages/category.js" /* webpackChunkName: "component---src-pages-category-js" */),
  "component---src-pages-categorychildren-js": () => import("./../../../src/pages/categorychildren.js" /* webpackChunkName: "component---src-pages-categorychildren-js" */),
  "component---src-pages-categoryman-js": () => import("./../../../src/pages/categoryman.js" /* webpackChunkName: "component---src-pages-categoryman-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kids-js": () => import("./../../../src/pages/kids.js" /* webpackChunkName: "component---src-pages-kids-js" */),
  "component---src-pages-man-js": () => import("./../../../src/pages/man.js" /* webpackChunkName: "component---src-pages-man-js" */),
  "component---src-pages-order-js": () => import("./../../../src/pages/order.js" /* webpackChunkName: "component---src-pages-order-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-sanity-children-slug-current-js": () => import("./../../../src/pages/{SanityChildren.slug__current}.js" /* webpackChunkName: "component---src-pages-sanity-children-slug-current-js" */),
  "component---src-pages-sanity-man-slug-current-js": () => import("./../../../src/pages/{SanityMan.slug__current}.js" /* webpackChunkName: "component---src-pages-sanity-man-slug-current-js" */),
  "component---src-pages-sanity-woman-slug-current-js": () => import("./../../../src/pages/{SanityWoman.slug__current}.js" /* webpackChunkName: "component---src-pages-sanity-woman-slug-current-js" */),
  "component---src-pages-woman-js": () => import("./../../../src/pages/woman.js" /* webpackChunkName: "component---src-pages-woman-js" */)
}

